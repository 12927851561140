<template>
  <div class="menu-social-jet">
    <NuxtLink to="/moderation/" class="relative">
      <PublisherMenuItem :class="{ active: route.name === 'moderation' }">
        <template #icon>
          <i class="fi fi-sr-time-quarter-past text-xl"></i>
          <div class="w-1 h-1 rounded-full bg-[#27C2A7] absolute top-2 left-11" v-if="store.moderateCount > 0"></div>
        </template>
        <template #text>Модерация</template>
        <template #info v-if="store.moderateCount > 0">
          <div class="bg-[#27C2A7] rounded-xl text-white font-extrabold text-xs px-1">+{{ store.moderateCount }}</div>
        </template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/moderation/clients">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-clients' }">
        <template #icon>
          <i class="fi fi-sr-user text-xl"></i>
        </template>
        <template #text>Клиенты</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/moderation/publishers">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-publishers' }">
        <template #icon>
          <i class="fi fi-sr-mode-portrait text-xl"></i>
        </template>
        <template #text>Паблишеры</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/moderation/orders">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-orders' }">
        <template #icon>
          <i class="fi fi-sr-document text-xl"></i>
        </template>
        <template #text>Заказы</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/moderation/statistics">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-statistics' }">
        <template #icon>
          <i class="fi fi-sr-pulse text-xl"></i>
        </template>
        <template #text>Статистика</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/moderation/channels">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-channels' }">
        <template #icon>
          <i class="fi fi-sr-paper-plane text-xl"></i>
        </template>
        <template #text>Каналы</template>
      </PublisherMenuItem>
    </NuxtLink>

    <MenuSeparator></MenuSeparator>

    <NuxtLink to="/moderation/settings">
      <PublisherMenuItem :class="{ active: route.name === 'moderation-settings' }">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>Настройки</template>
      </PublisherMenuItem>
    </NuxtLink>

    
    <!--    <MenuSeparator>-->
    <!--      Рекламные кампании-->
    <!--    </MenuSeparator>-->
    <!-- <NuxtLink to="/publisher">
      <PublisherMenuItem :class="{ active: main_routes.includes(route.name) }">
        <template #icon>
          <i class="fi fi-sr-apps text-xl"></i>
        </template>
        <template #text>Мои каналы</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/orders">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-orders' }">
        <template #icon>
          <i class="fi fi-sr-journal-alt text-xl"></i>
        </template>
        <template #text>Заказы</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/">
      <PublisherMenuItem :class="{ active: route.name === 'creatives' }">
        <template #icon>
          <i class="fi fi-sr-comment-alt text-xl"></i>
        </template>
        <template #text>Диалоги</template>
        <template #notice><div class="chats-count">+3</div></template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/history">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-history' }">
        <template #icon>
          <Icon name="mdi:clipboard-text-time" size="24" :class="{ 'text-[#00AEE8]': route.name === 'publisher-history' }"/>
        </template>
        <template #text>История размещений</template>
      </PublisherMenuItem>
    </NuxtLink>

        <MenuSeparator>
          Настройки
        </MenuSeparator>

    <NuxtLink to="/publisher/payments">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-payments' }">
        <template #icon>
          <i class="fi fi-sr-credit-card text-xl"></i>
        </template>
        <template #text>Выплаты</template>
        <!-- TODO поставить реальный бланс -->
        <!-- <template #info>1 252₽</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/settings">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-settings' }">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>Настройки</template>
      </PublisherMenuItem>
    </NuxtLink>

    <MenuSeparator>
      Сервис
    </MenuSeparator>

    <NuxtLink to="/creatives">
      <PublisherMenuItem :class="{ active: route.name === 'creatives' }">
        <template #icon>
          <i class="fi fi-sr-id-badge text-xl"></i>
        </template>
        <template #text>Новости</template>
      </PublisherMenuItem>
    </NuxtLink>



    <NuxtLink to="/support">
      <PublisherMenuItem :class="{ active: route.name === 'support' }">
        <template #icon>
          <i class="fi fi-sr-time-twenty-four text-xl"></i>
        </template>
        <template #text>Поддержка</template>
      </PublisherMenuItem>
    </NuxtLink> -->
  </div>
</template>

<script setup lang="ts">
import { useModerationStore } from '~/store/ModerationModule';

const route = useRoute();
const store = useModerationStore();

</script>

<style>
a {
  width: 100%;
}
.menu-social-jet {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
.chats-count {
  color: white;
  background: #27C2A7;
  border-radius: 15px;
  text-align: center;
  height: 16px;
  width: 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 800;
  cursor: pointer;
}
</style>
